<template>
  <form
    class="form-register col-5 my-3 px-3 align-items-center rounded-3 border shadow-lg needs-validation"
    novalidate
    @submit="checkForm"
  >
    <img class="mb-4" src="../assets/WeekendClip.png" alt width="120" height="120" />
    <h1 class="h3 mb-3 fw-normal">Profile</h1>
    <div class="form-group py-3">
      <div class="form-floating">
        <input type="email" class="form-control" id="email" v-model="email" required disabled />
        <label for="floatingInput">Email address</label>
        <div class="invalid-feedback">Email address is required</div>
      </div>
    </div>
    <div class="form-group py-3">
      <div class="form-floating">
        <input
          type="displayName"
          class="form-control"
          id="displayName"
          v-model="displayName"
          required
        />
        <label for="floatingInput">Display name</label>
        <div class="invalid-feedback">Display name is required</div>
      </div>
    </div>
    <div class="form-group py-3">
      <div class="form-floating">
        <input type="phoneNumber" class="form-control" id="phoneNumber" v-model="phoneNumber" />
        <label for="floatingInput">Phone number</label>
      </div>
    </div>
    <div class="form-group">
      <div v-if="errors.length">
        <b>There was a problem:</b>
        <div
          v-for="error in errors"
          v-bind:key="error"
          class="alert alert-warning"
          role="alert"
        >{{ error }}</div>
      </div>
    </div>
    <div class="form-group py-3">
      <button
        class="w-100 btn btn-lg btn-primary"
        @click="doUpdate"
        ref="updateProfileButton"
        type="button"
      >Update</button>
    </div>
    <p class="mt-4 mb-3 text-muted">&copy; 2021</p>
  </form>
</template>
<script>
import MD5 from "crypto-js/md5";
import firebase from "firebase/app";
import "firebase/auth";
//import { EventBus } from "@/event-bus";
export default {
  name: "AccountProfile",
  data() {
    return {
      validClass: "is-valid",
      invalidEmailClass: "",
      isValidEmail: false,
      displayName: "",
      email: "",
      emailVerified: "",
      isAnonymous: "",
      metadata: "",
      multiFactor: "",
      phoneNumber: "",
      photoURL: "",
      providerData: "",
      providerId: "",
      refreshToken: "",
      tenantId: "",
      uid: "",

      errors: [],
    };
  },
  mounted() {
    //debugger; // eslint-disable-line no-debugger
    var currentUser = this.$store.getters.getUser;
    this.displayName = currentUser.displayName;
    this.email = currentUser.email;
    this.emailVerified = currentUser.emailVerified;
    this.isAnonymous = currentUser.isAnonymous;
    this.metadata = currentUser.metadata;
    this.multiFactor = currentUser.multiFactor;
    this.phoneNumber = currentUser.phoneNumber;
    this.photoURL = currentUser.photoURL;
    this.providerData = currentUser.providerData;
    this.providerId = currentUser.providerId;
    this.refreshToken = currentUser.refreshToken;
    this.tenantId = currentUser.tenantId;
    this.uid = currentUser.uid;
  },
  props: {},
  methods: {
    checkForm: function (e) {
      this.errors = [];

      if (!this.email) {
        this.errors.push("Email required.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.");
      }

      if (!this.errors.length) {
        return true;
      }

      e.preventDefault();
      var form = document.querySelector(`form`);
      form.classList.add("was-validated");
    },
    checkEmail: function () {
      if (this.email == "") {
        this.isValidEmail = false;
        this.invalidEmailClass = "";
      } else {
        if (this.validEmail(this.email)) {
          this.isValidEmail = true;
        } else {
          this.invalidEmailClass = "is-invalid";
          this.isValidEmail = false;
        }
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    // global function

    doUpdate: function () {
      if (!this.checkForm()) {
        return;
      }
      debugger; // eslint-disable-line no-debugger
      const user = firebase.auth().currentUser;

      if (user) {
        
        var photoHash = MD5(this.email.trim().toLowerCase());
        var photoURL = "https://www.gravatar.com/avatar/" + photoHash;
        user
          .updateProfile({
            displayName: this.displayName,
            phoneNumber: this.phoneNumber,
            photoURL: photoURL,
          })
          .then((response) => {
            // Update successful
            // ...
            
            alert("Woohoo!")
            alert(JSON.stringify(response))
          })
          .catch((error) => {
            // An error occurred
             this.errors.push(error);
          });
      } else {
        this.errors.push("Unable to get your user profile to update.");
      }
    },
  },
};
</script>

<style scoped>
.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}
@media (min-width: 992px) {
  .rounded-lg-3 {
    border-radius: 0.3rem;
  }
}
</style><style type="text/css">
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-floating:focus-within {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.login-divider {
  height: 0.1rem;
  background-color: rgba(0, 0, 0, 0.1);
}
.bottom-form-link {
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 0;
}
.bottom-form-link li {
  display: inline-block;
  list-style: none;
}
.bottom-form-link ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  margin-block-start: 1em;
  -webkit-margin-after: 1em;
  margin-block-end: 1em;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
  -webkit-padding-start: 40px;
  padding-inline-start: 40px;
}
.bottom-form-link li:not(:first-child)::before {
  content: "\2022";
  margin: 0 8px 0px 4px;
}

.form-register {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-register .checkbox {
  font-weight: 400;
}
.form-register .form-floating:focus-within {
  z-index: 2;
}
.form-register input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-register input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>